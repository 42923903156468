/**
 * Returns a function that serializes concurrent invocations. Think `_.memoize` but only while the
 * promise is pending.
 */
export function serializeConcurrent<TArg, TResult>(
  func: (arg: TArg) => Promise<TResult>,
  hashFn: (arg: TArg) => string = String
): (arg: TArg) => Promise<TResult> {
  const cache = new Map<string, Promise<TResult>>();
  const serializedFunc = (arg: TArg): Promise<TResult> => {
    const key = hashFn(arg);
    let promise = cache.get(key);
    if (promise == null) {
      promise = func(arg);
      cache.set(key, promise);
      promise.finally(() => {
        cache.delete(key);
      });
    }
    return promise;
  };
  return serializedFunc;
}
